import {
  camelCase
} from 'camel-case'

import {
  getContentTagData
} from '../utils/ui/get-content-tag-data'

export default {
  mounted () {
    this._.forEach(getContentTagData(), (v, k) => {
      this[camelCase(k)] = v
    })
  }
}
