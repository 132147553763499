export default pluginNames => {
  const plugins = pluginNames.map(pluginName => {
    return require(`./${pluginName}`).default
  })
  return Vue => {
    plugins.forEach(plugin => {
      Vue.use(plugin)
    })
  }
}
