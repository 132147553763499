export default {
  methods: {
    formatBedTypeCombinationItems (bedTypeCombinationItems) {
      return this._.sortBy(
        bedTypeCombinationItems
          .map(bedTypeCombinationItem => ({
            count: bedTypeCombinationItem.count,
            bed_type: {
              name: this._.get(bedTypeCombinationItem, 'bed_type.name'),
              occupancy: this._.get(bedTypeCombinationItem, 'bed_type.occupancy')
            }
          }))
          .filter(bedTypeCombinationItem => this._.get(bedTypeCombinationItem, 'bed_type.name') && this._.get(bedTypeCombinationItem, 'bed_type.occupancy')),
        'bed_type.name'
      )
    },
    formatHotelRoom (hotelRoom) {
      return {
        bed_type_combination_items: this.formatBedTypeCombinationItems(hotelRoom.bed_type_combination_items),
        room_type: {
          name: hotelRoom.room_type.name
        }
      }
    }
  }
}
