export default {
  async mounted () {
    const dataEl = document.getElementById('data')

    if (dataEl) {
      const organization = JSON.parse(dataEl.getAttribute('data-organization'))
      if (organization) {
        if (organization.currency) {
          await this.$store.dispatch('organizationStore/updateCurrency', {
            currency: organization.currency,
            currencySymbol: organization.currencySymbol
          })
        }

        if (organization.organization_tracking_token) {
          await this.$store.dispatch('organizationStore/updateOrganizationTrackingToken', organization.organization_tracking_token)
        }

        if (organization.slug) {
          await this.$store.dispatch('organizationStore/updateSlug', organization.slug)
        }
      }
    }
  }
}
