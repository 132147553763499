export default mixinNames => {
  const mixins = mixinNames.map(mixinName => {
    return require(`./${mixinName}`).default
  })

  return Vue => {
    mixins.forEach(mixin => {
      Vue.mixin(mixin)
    })
  }
}
