export default {
  install (Vue) {
    const getComputedStyle = (element, prop) => {
      if (element.currentStyle) {
        return element.currentStyle[prop]
      } else if (window.getComputedStyle && window.getComputedStyle(element)) {
        return window.getComputedStyle(element).getPropertyValue(prop)
      } else {
        return element.style[prop]
      }
    }

    const getCSSProperty = name => getComputedStyle(document.body, `--${name}`)

    const getColor = name => getCSSProperty(`color-${name}`)

    Vue.getColor = getColor
    Vue.prototype.getColor = getColor
  }
}
