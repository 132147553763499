import _ from 'lodash'

import CURRENCIES from '@/assets/misc/currencies.json'

export default {
  install (Vue) {
    const isZeroDecimal = currency => _.get(CURRENCIES, currency.toUpperCase() + '.zero_decimal', false)

    const denormalizeAmount = ({
      amount,
      currency
    }) => {
      if (!currency) return

      return (isZeroDecimal(currency) && amount) || Number((amount / 100).toFixed(2))
    }

    const normalizeAmount = ({
      amount,
      currency
    }) => {
      if (!currency) return

      // https://stripe.com/docs/currencies#special-cases
      if (currency === 'UGX') {
        return Math.ceil(Math.floor(amount * 100) / 100) * 100
      }

      return (isZeroDecimal(currency) && amount) || Math.trunc(Number((100 * amount).toFixed(2)))
    }

    const $currency = {
      denormalizeAmount,
      normalizeAmount
    }

    Vue.prototype.$currency = $currency
    Vue.$currency = $currency
  }
}
