export default {
  methods: {
    validateField (ref, scope) {
      if (!scope) {
        if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.errors.has(ref)
        }

        return null
      } else {
        if (this._.get(this.veeFields, `[$${scope}][${ref}]`) && this.veeFields[`$${scope}`][ref] && (this.veeFields[`$${scope}`][ref].dirty || this.veeFields[`$${scope}`][ref].validated)) {
          return !this.errors.has(`${scope}.${ref}`)
        }
      }
    }
  }
}
