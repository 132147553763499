import Vue from 'vue'

const eventBus = {}
let $eventBus

eventBus.install = Vue => {
  const eventBus = new Vue()
  $eventBus = eventBus
  Vue.$bus = eventBus
  Vue.prototype.$bus = eventBus
}

Vue.use(eventBus)

export default $eventBus
