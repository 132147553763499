/* rails-erb-loader-dependencies helpers/application_helper */
import Vue from 'vue'


            import axios from 'axios'
          
          import { ButtonPlugin } from 'bootstrap-vue'
          Vue.use(ButtonPlugin)
        
          import { CardPlugin } from 'bootstrap-vue'
          Vue.use(CardPlugin)
        
          import { FormPlugin } from 'bootstrap-vue'
          Vue.use(FormPlugin)
        
          import { FormCheckboxPlugin } from 'bootstrap-vue'
          Vue.use(FormCheckboxPlugin)
        
          import { FormGroupPlugin } from 'bootstrap-vue'
          Vue.use(FormGroupPlugin)
        
          import { FormInputPlugin } from 'bootstrap-vue'
          Vue.use(FormInputPlugin)
        
          import { LayoutPlugin } from 'bootstrap-vue'
          Vue.use(LayoutPlugin)
        
          import { LinkPlugin } from 'bootstrap-vue'
          Vue.use(LinkPlugin)
        
          import _ from 'lodash'

          Vue.use({
            install (Vue) {
              Vue._ = _
              Vue.prototype._ = _
            }
          })
        
          import VeeValidate, {
            Validator
          } from 'vee-validate'
          import en from 'vee-validate/dist/locale/en';
          import fr from 'vee-validate/dist/locale/fr';

          const dictionaries = {
            en,
            fr
          }

          Vue.use(VeeValidate, {
            classes: true,
            classNames: {
              valid: 'is-valid',
              invalid: 'is-invalid'
            },
            fieldsBagName: 'veeFields',
            mode: 'eager'
          })

          Vue.mixin({
            created () {
              Validator.localize(document.body.lang, dictionaries[document.body.lang])
            }
          })
        
            import vueNotification from 'vue-notification'
            Vue.use(vueNotification)
          
            import vueRouter from 'vue-router'
            Vue.use(vueRouter)
          


          import LogoRevolugo from '../../../components/shared/LogoRevolugo/LogoRevolugo.vue'
          Vue.component('LogoRevolugo', LogoRevolugo)
        
          import VueNotification from '../../../components/shared/VueNotification/VueNotification.vue'
          Vue.component('VueNotification', VueNotification)
        


      import {
        library
      } from '@fortawesome/fontawesome-svg-core'
      import {
        FontAwesomeIcon
      } from '@fortawesome/vue-fontawesome'
      import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons'
      library.add(faSpinner, faTimes)

      Vue.mixin({
        components: {
          'fa-icon': FontAwesomeIcon
        }
      })
    

import importMixins from '../../../mixins/index.js'
import removePageLoader from '../../../mixins/remove-page-loader'
import importPlugins from '../../../plugins/index.js'
import SegmentTracker from '../../../lib/segment-tracker.js'
import router from '../../../router'

importMixins([
  'translation'
])(Vue)

importPlugins([
  'get-color'
])(Vue)

document.addEventListener('DOMContentLoaded', () => {
  axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.common['Accept'] = 'application/json'

  new Vue({
    el: '#app',
    mixins: [removePageLoader],
    data () {
      return {
        email: null,
        password: null,
        rememberMe: null,
        isSubmitting: false
      }
    },
    methods: {
      async submit () {
        this.isSubmitting = true
        await this.$validator.validateAll()
          .then(result => {
            if (!result) {
              this.$notify({
                type: 'error',
                text: this.$t('users.sessions.errors.validation')
              })
            } else {
              return axios({
                method: 'POST',
                url: '/users/sign_in',
                data: {
                  user: {
                    email: this.email,
                    password: this.password,
                    remember_me: this.rememberMe
                  }
                }
              })
                .then((data) => {
                  SegmentTracker.signedIn({
                    email: this.email
                  })
                  document.location.href = this._.get(this.$route, 'query.return_to', '/')
                })
                .catch(err => {
                  console.error(err)
                  this.$notify({
                    type: 'error',
                    text: this._.get(err, 'response.data.message') ? err.response.data.message : this.$t('users.sessions.errors.something_went_wrong')
                  })
                })
            }
          })
        this.isSubmitting = false
      }
    },
    router
  })
})
