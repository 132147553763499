import moment from 'moment'

export default {
  organizationCreated: ({
    organization
  }) => {
    window.analytics && window.analytics.track('Workspace Created', {
      created : moment().format('YYYY-MM-DD'),
      workspace_slug: organization.slug
    })
  },
  payoutRequested ({
    payoutAmount,
    organizationSlug,
    userEmail
  }) {
    window.analytics && window.analytics.track('Payout Requested', {
      amount: payoutAmount,
      email: userEmail,
      workspace_slug: organizationSlug
    })
  },
  signedIn: ({
    email
  }) => {
    window.analytics && window.analytics.track('Signed In', {
      email
    })
  },
  signedOut: ({
    email,
    organization
  }) => {
    window.analytics && window.analytics.track('Signed Out', {
      email,
      workspace_slug: organization.slug
    })
    window.analytics && window.analytics.reset()
  },
  signedUp: ({
    email,
    firstName,
    lastName,
    organization
  }) => {
    window.analytics && window.analytics.track('Signed Up', {
      email,
      company: { name: organization.name },
      created: moment().format('YYYY-MM-DD'),
      first_name: firstName,
      last_name: lastName,
      workspace_slug: organization.slug
    })
  },
  userInvited ({
    invitee,
    inviterEmail,
    organizationSlug
  }) {
    window.analytics && window.analytics.track('User Invited', {
      invitee: invitee.email,
      inviter: inviterEmail,
      role: invitee.role,
      workspace_slug: organizationSlug
    })
  },
  widgetCreated ({
    organizationSlug,
    userEmail,
    widget
  }) {
    window.analytics && window.analytics.track('Widget Created', {
      email: userEmail,
      address: widget.address,
      created: moment().format('YYYY-MM-DD'),
      end_date: moment(widget.check_out_date).format('YYYY-MM-DD'),
      start_date: moment(widget.check_in_date).format('YYYY-MM-DD'),
      widget_name: widget.name,
      widget_slug: widget.slug,
      workspace_slug: organizationSlug
    })
  }
}