export const getContentTagData = () => {
  const dataEl = document.getElementById('data')

  if (!dataEl) return {}

  return [...dataEl.attributes].filter(attr => attr.name.startsWith('data-')).reduce((obj, attr) => {
    try {
      return {
        ...obj,
        [attr.name.replace('data-', '')]: JSON.parse(attr.value)
      }
    } catch {
      return {
        ...obj,
        [attr.name.replace('data-', '')]: attr.value
      }
    }
  }, {})
}

export default keys => {
  const dataEl = document.getElementById('data')

  if (!dataEl) return {}

  return keys.reduce((obj, key) => {
    let value

    if (dataEl.getAttribute(`data-${key}`)) {
      try {
        value = JSON.parse(dataEl.getAttribute(`data-${key}`))
      } catch (err) {
        value = dataEl.getAttribute(`data-${key}`)
      }
    }

    return {
      ...obj,
      [key]: value
    }
  }, {})
}
