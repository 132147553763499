import _ from 'lodash'

export default {
  methods: {
    $t (path, interpolations) {
      if (!_.isEmpty(interpolations)) {
        let result = _.get(window._translations, path)
        _.forEach(interpolations, (v, k) => {
          const regexp = new RegExp(`%{${k}}`, 'g')
          result = result.replace(regexp, v)
        })
        return result
      }
      return _.get(window._translations, path)
    }
  }
}
