export default () => {
  const pageLoader = document.getElementById('page-loader')
  const applicationLayout = document.getElementById('application-layout')

  if (pageLoader && applicationLayout) {
    setTimeout(() => {
      applicationLayout.style.display = 'initial'
      pageLoader.remove()
    }, 0)
  }
}
