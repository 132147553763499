export default {
  async mounted () {
    const dataEl = document.getElementById('sidebar-data')
    if (dataEl) {
      const currentUser = JSON.parse(dataEl.getAttribute('data-user'))
      if (currentUser) {
        await this.$store.dispatch('globalStore/updateUser', currentUser)
      }
    }
    await this.$store.dispatch('globalStore/updateLocale', document.body.lang)
  }
}
